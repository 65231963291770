import React from "react"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import { Main } from "page_components/404"

const NotFoundPage = () => (
  <Layout seo={{ title: "404: Not found" }}>
    <PageHeader title="#404" />
    <Main />
  </Layout>
)

export default NotFoundPage
