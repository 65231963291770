import "./styles.scss"

import React from "react"

import Button from "components/Button"

const Main = () => {
  return (
    <section className="not-found-main">
      <div className="container-fluid">
        <h2>Podana strona nie istnieje</h2>
        <Button to="/">strona główna</Button>
      </div>
    </section>
  )
}

export default Main
